<script>
  import { required, maxLength } from "vuelidate/lib/validators";
  import validationMessages from "@/components/validations";
  import Monitor from "@/services/Monitor";


  export default {
    components: { validationMessages },

    data() {
      return {
        tenant: "",
        username: "",
        password: "",
        tenants_options: [],
        showModal: false,
        submitted: false,
        tryingToSubmit: false,
        showLoader: false,
        csrf_token: localStorage.getItem("csrf_token"),
        error: null,
      };
    },

    validations: {
      tenant: {
        required,
      },
      username: {
        required,
        maxLength: maxLength(100),
      },
      password: {
        required,
        maxLength: maxLength(255),
      },
    },

    methods: {
      closeModal() {
        this.showModal = false;
      },

      async getTenants() {
        try {
          this.showLoader = true;
          const response = await Monitor.getTenants();
          this.tenants_options = response.data.tenants
        } catch (error) {
          console.log('Error fetching tenants:', error);
        } finally {
          this.showLoader = false;
        }
      },

      async addVPNUser() {
        this.tryingToSubmit = true;
        this.submitted = true;
        this.$v.$touch();

        if (this.$v.$invalid) {
          this.tryingToSubmit = false;
          return;
        }

        let formData = {
          tenant: this.tenant,
          username: this.username,
          password: this.password
        };
        try {
          const response = await Monitor.addVPNUsers(formData);
          if (response.error) {
            this.failedmsg(response.error);
          } else {
            this.successmsg('Successfully saved new VPN User');
            this.closeModal();
          }
        } catch (error) {
          this.error = error.response?.data?.error || "An error occurred";
          this.failedmsg('Failed to save new VPN User', this.error);
        } finally {
          this.tryingToSubmit = false;
          this.refreshData();
        }
      },

      refreshData() {
        this.$emit("onRefresh");
      },

      resetForm() {
        this.tenant = "";
        this.username = "";
        this.password = "";
        this.$v.$reset();
        this.submitted = false;
      },

      async modalShown() {
        await this.getTenants();
      }
    },
  };
</script>

<template>
  <b-modal @shown="modalShown" id="add_vpn_user" size="l" v-model="showModal" @hidden="resetForm" title="Add new VPN User" title-class="font-18">
    <form @submit.prevent="addVPNUser" v-if="!showLoader">
      <div class="row">
        <div v-if="$v.$error" class="text-danger">Complete all fields</div>
        <b-card-text>
          <div class="row">
            <div class="col-sm-12">
              <b-form-group label="Select Tenant" label-for="formrow-tenant-input" class="mb-3">
                <b-form-select
                        v-model="tenant"
                        :options="tenants_options"
                        :class="{ 'is-invalid': submitted && $v.tenant.$error }"
                        class="form-select"
                ></b-form-select>
                <validationMessages v-if="submitted" :fieldName="'Tenant'" :validationName="$v.tenant"></validationMessages>
              </b-form-group>

              <b-form-group label="Username" label-for="formrow-name-input" class="mb-3">
                <b-form-input v-model.trim="username"></b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Username'" :validationName="$v.username"></validationMessages>
              </b-form-group>

              <b-form-group label="Password" label-for="formrow-url-input" class="mb-3">
                <b-form-input v-model.trim="password"></b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Password'" :validationName="$v.password"></validationMessages>
              </b-form-group>

              <input type="hidden" name="csrf_token" v-model="csrf_token">
            </div>
          </div>
        </b-card-text>
      </div>
    </form>
    <div class="card-body d-flex align-items-center justify-content-center" v-else>
      <b-spinner large></b-spinner>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Close</b-button>
      <b-button variant="primary" @click="addVPNUser" :disabled="tryingToSubmit || showLoader">
        <b-spinner v-show="tryingToSubmit" small></b-spinner>Submit
      </b-button>
    </template>
  </b-modal>
</template>
