<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Monitor from "@/services/Monitor";
import Swal from "sweetalert2";
import ModalAddVPNUser from "@/components/modals/monitor/modalAddVPNUser";

export default {
  components: {
    Layout,
    PageHeader,
    ModalAddVPNUser
  },
  page: {
    title: "VPN Users Management",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      error: null,
      tableData: [],
      title: "VPN Users Management",
      items: [
        {
          text: "Monitor",
        },
        {
          text: "VPN Users",
          active: true,
        },
      ],
      filterInput: {},
      isBusy: false,
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "created",
      sortDesc: true,
      tenantSslData: [],
      fields: [
        {
          key: "tenant",
          label: "Tenant",
          visible: true,
          sortable: true,
        },
        {
          key: "username",
          label: "Username",
          visible: true,
        },
        {
          key: "password",
          label: "Password",
          visible: true,
        },
        {
          key: "created",
          label: "Created",
          visible: true,
          sortable: true,
        },
        {
          key: "action",
          label: "Action",
        }
      ],
      filterData: false,
      verifyInput: "",
      loading: false,
    };
  },

  async created() {
    await this.getVPNUsers();
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async getVPNUsers() {
      try {
        this.filterData = true;
        this.isBusy = true;
        const response = await Monitor.getVPNUsers();
        this.tableData = response.data.data;
        this.totalRows = this.tableData.length;
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
        this.error = error.response?.data?.error || "An error occurred while fetching data.";
        this.tableData = [];
        this.totalRows = 0;
      }
    },

    callModalAddVPNUser() {
      this.$bvModal.show("add_vpn_user");
    },

    removeVPNUsers(username){
        Swal.fire({
            title: "Remove VPN User <br/>"+username+"?",
            icon: "warning",
            html: `
                        <p>Type "Delete" to confirm:</p>
                        <input type="text" id="deleteConfirmation" class="swal2-input" autocomplete="off">
                    `,
            showCancelButton: true,
            confirmButtonColor: "#34c38f",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: "Delete",
            showLoaderOnConfirm: true,
            preConfirm: () => {
                const confirmationText = Swal.getPopup().querySelector(
                    "#deleteConfirmation"
                ).value;
                if (confirmationText.toLowerCase() !== "delete") {
                    Swal.showValidationMessage('You must type "Delete" to confirm');
                    return false;
                }

                return Monitor.removeVPNUsers(username)
                    .then((response) => {
                        return response;
                    })
                    .catch((error) => {
                        let errorMsg = error.response.data.error
                            ? error.response.data.error
                            : "";
                        this.failedmsg("Fail!", errorMsg);
                    });
            },
        }).then((result) => {
            if (result.isConfirmed) {
                const res = result.value.data ? result.value.data.data : false;
                const error = result.value.data.error
                    ? result.value.data.error
                    : "Failed";

                if (res) {
                    this.successmsg('Removed VPN User', 'VPN user is successfully removed')
                    setTimeout(() => {
                        this.getVPNUsers();
                    }, 2000);
                } else {
                    this.warningmsg("Fail!", error);
                }
            }
        });
    },

    showPassword(password){
      Swal.fire({
          html: "<strong>"+password+"</strong>",
          showCancelButton: false,
          confirmButtonColor: "#34c38f",
          confirmButtonText: "OK",
      })
    },

  },
  middleware: "authentication",
};

</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" :fields="fields"/>
    <div class="row">
      <div class="col-12">
        <div class="card" v-show="filterData">
          <div class="card-body">
            <a href="javascript:void(0);" class="btn btn-success waves-effect waves-light"
               @click="callModalAddVPNUser">
              <i class="mdi mdi-plus me-2"></i>Add New VPN User
            </a>

            <div class="row mt-4">
              <div class="col d-inline-flex align-items-center">
                <h4 class="card-title m-0">Total VPN User Accounts: {{ totalRows }}</h4>
              </div>
            </div>
            <div class="row mt-4" v-show="filterData">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="deals-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>

            <!-- Table -->
            <div class="table-responsive mb-0" v-show="filterData">
              <b-table
                  :items="tableData"
                  :busy="isBusy"
                  :fields="fields"
                  :visible="fields.visible"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                  show-empty
                  empty-text="No Data Found"
              >
                <template v-slot:cell(action)="data">
                  <ul class="list-inline mb-0">
<!--                    <li class="list-inline-item">-->
<!--                      <a href="javascript:void(0);" class="px-2 text-primary" title="Edit Tenant Sll Link" @click="callModalEditTeamDeal(data.item)">-->
<!--                        <i class="uil uil-pen font-size-18"></i>-->
<!--                      </a>-->
<!--                    </li>-->
                    <li class="list-inline-item">
                      <a href="javascript:void(0);" class="px-2 text-danger" title="Remove VPN User" @click="removeVPNUsers(data.item.username)">
                        <i class="uil uil-trash-alt font-size-18"></i>
                      </a>
                    </li>
                  </ul>
                </template>

                <template v-slot:cell(password)="data">
                  <p class="font-size-16 mb-2"><span class="text-strong"></span> <a
                          href="javascript:void(0);"
                          @click="showPassword(data.item.password)"
                          title="View Password"
                  >
                      <i class="uil uil-eye font-size-18"></i>
                  </a></p>
                </template>


                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>

              </b-table>
            </div>
            <!--Table end -->

            <div class="row" v-show="filterData">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- MODALS -->
      <ModalAddVPNUser @onRefresh="getVPNUsers()"></ModalAddVPNUser>
    <!-- /MODALS -->
  </Layout>
</template>

